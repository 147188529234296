.control-panel{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3px;
}

.volume-control{
    display: flex;
}

.settings-menu {
    position: absolute;
    top: -269%;
    right: 0;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;


    border-radius: 5px;
    background: rgba(255, 255, 255, 0.80);
    display: flex;
    width: 230px;
    padding-bottom: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: -3px;

}

.setting-option{
    display: flex;
    width: 188px;
    padding: 20px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid var(--dark-01, #212121);
}

.setting-value{
    display: flex;
    align-items: center;
    gap: 30px;
}

.speed-val{
    color: var(--dark-01, #212121);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.settings-menu button {
    display: block;
    padding: 10px;
    width: 100%;
    text-align: left;
    border: none;
    background: none;
    cursor: pointer;
}

.settings-menu button:hover {
    background-color: #f5f5f5;
}
  