.upload-video-form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.upload-video-form-container form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.upload-video-form-container input[type="text"]{
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%; /* Make input and button full width */
    margin-bottom: 10px;
}

.upload-video-form-container input[type="file"]{
    width: 100%; /* Make input and button full width */
    margin-bottom: 10px;
    color: transparent;
}
.upload-video-form-container button {
  width: 100%; /* Make input and button full width */
  margin-bottom: 10px;
}

.upload-video-btn {
  padding: 10px 20px;
  background-color: #007bff; /* Blue */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.remove-video-btn {
  padding: 10px 20px;
  background-color: #dc3545; /* Red */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.remove-video-btn:hover {
  background-color: #c82333; /* Darker red on hover */
}

.upload-video-form-container button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.upload-video-btn:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.upload-video-form-container select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%; /* Make select full width */
  margin-bottom: 10px;
  background-color: #fff; /* White background */
  color: #555; /* Dark gray text */
}

.upload-video-form-container select:hover {
  border-color: #007bff; /* Blue border on hover */
}

.upload-video-form-container select:focus {
  outline: none;
  border-color: #007bff; /* Blue border when focused */
}

/* Define a general style for the buttons */
.upload-button {
  padding: 5px;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
}

/* Style for the active button */
.upload-button.active {
  background-color: #007bff; /* Blue color as an example */
  color: white;
}

/* Style for the inactive button */
.upload-button:not(.active) {
  background-color: #f0f0f0; /* Light gray color as an example */
  color: #333; /* Dark color for text */
}
