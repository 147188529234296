.move-form-container{
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
}

.move-form-select-field{
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
}

.move-form-select-field:focus{
    outline: none;
    border-color: #007bff;
}

.move-video-btn{
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.move-video-btn:hover{
    background-color: #0056b3;
}

.option-disabled{
    color: #999;
}