.main-home-left-panel {
    width: 14vw;
    background-color: var(--buttonColor);
    box-shadow: 0px 8px 50px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
}

.main-home-avetiBranding {
    background-color: transparent;
    padding-top: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.main-avetiproductname {
    color: var(--textPrimaryBlack);
    font-weight: 700;
    font-family: "Lato", sans-serif;
    font-size: 20px;
    margin-top: 40px;
}

.main-home-aveti-nav{
    width: 80%;
}

.main-aveti-left-nav-ul{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    border-radius: 5px;
}

.main-aveti-left-nav-li{
    list-style: none;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    gap: 20px;
    padding-left: 10px;
    flex-direction: row;
    border-radius: 5px;
    text-decoration: none;
    color: inherit;
}

.selected{
    color: var(--buttonColor);
    font-family: "Lato", sans-serif;

    background-color: var(--navButton) !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.active{
    color: var(--buttonColor);
    font-family: "Lato", sans-serif;

    background-color: var(--navButton) !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}