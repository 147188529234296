.ppt-main-view-container {
  margin-top: 20px;
  width: 56vw;
}

.ppt-main-view-audio-player-container {
  margin-top: 10px;
}

.ppt-main-view-header {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.ppt-main-view-body {
  width: auto;
  height: auto;
  border-radius: 5px;
}

.ppt-selected-slide {
  width: 856px;
  height: 461px;
  border-radius: 5px;
}

.render-btn-ppt-main {
  width: 173px;
  height: 48px;
  display: flex;
  padding: 0 28px;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: none;
  background-color: #1167e5;
}

.render-btn-text-ppt-main {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.ppt-main-audio-player {
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 5px;
}

audio {
  width: 100%;
}
