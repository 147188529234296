.primemodal {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.primemodal-content {
    background-color: white;
    border-radius: 5px;
    padding: 20px 50px;
    width: 50%;
    height: 30%;
    opacity: 1;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid black;
    flex-direction: column;
}
.primemodal-span {
    display: block;
    width: 80%;
    height: 1px;
    background: black;
}
.primemodal-note{
    color: rgb(176, 152, 152);
}
.primemodal-close {
    background: blue;
    color: white;
    border: none;
    padding: 15px 25px;
    width: 81%;
    cursor: pointer;
    border-radius: 5px;

}