.video-ppt-list-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* .video-ppt-list-body {
  max-height: 72vh;
  overflow-y: scroll;
} */
.video-ppt-list-container-header {
  display: flex;
  gap: 20px;
}

.refresh-button {
  display: flex;
  padding: 6.5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  color: white;
  border-radius: 5px;
  background: var(--primary-01, #1167e5);
}
