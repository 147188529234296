.ref-ppt-btn{
    width: 200px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: #fff;
    border: 1px solid #1167E5;
    border-radius: 5px;
}

.ref-slide-notes{
    background-color: antiquewhite;
    padding: 1px 10px;
    margin-bottom: 20px;
}

.ref-ppt-btn:hover{
    background-color: #fff;
}

.ref-ppt-icon{
    color: #1167E5;
}

.ref-ppt-icon:hover{
    color: #1167E5;
}