.main-container {
  padding: 50px;
  display: grid;
  grid-template-columns: minmax(100px, 25%) minmax(100px, 75%);
  column-gap: 100px;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #1167e5;
  border-radius: 10px;
}

.slide-preview-container-wrapper {
  height: 48vw;
  overflow-y: auto;
}

.slide-preview-container .add-slide-btn-container {
  visibility: hidden;
}

.slide-preview-container:hover .add-slide-btn-container {
  visibility: visible;
}

.slide-preview-container .delete-slide-container {
  visibility: hidden;
}

.slide-preview-container:hover .delete-slide-container {
  visibility: visible;
}

.delete-slide-container:hover {
  box-shadow: #212121 0px 0px 0px 1px inset;
}

.right-panel-container {
  width: 56vw;
}

.left-panel-container {
  display: flex;
  flex-direction: column;
  gap: 1px;
  margin-top: -15px;
}

.left-panel-navigation {
  display: flex;
  align-items: center;
  /* position: fixed; */
  top: 0;
  gap: 2px;
}

.navigation-text {
  color: #212121;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.slide-preview-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.slide-preview-selected {
  width: 318px;
  height: 196px;
  border-radius: 5px;
  border: 3px solid #1167e5;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

#selected {
  border-radius: 6px;
}

.slide-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 318px;
  height: 196px;
  border-radius: 5px;
  background: lightgray 0px -27.684px / 100% 243.274% no-repeat, #d9d9d9;
}

.preview-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.edit-btn {
  width: 170px;
  max-height: 48px;
  display: flex;
  padding: 13px 28px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  border: none;
  background: #0045aa;
}

.note-update-btn {
  width: 200px;
  max-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: none;
  background: #0045aa;
}

.note-btn-text {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 540;
  line-height: normal;
}

.edit-btn-text {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 540;
  line-height: normal;
}

.play-btn {
  width: 200px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #fff;
  border: 1px solid #1167e5;
  border-radius: 5px;
}

.play-btn:hover {
  background-color: #fff;
}

.play-btn-text {
  color: #1167e5;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.render-btn {
  width: 173px;
  height: 48px;
  display: flex;
  padding: 0 28px;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: none;
  background-color: #1167e5;
}

.render-btn-modal {
  width: 173px;
  height: 48px;
  display: flex;
  padding: 0 28px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 5px;
  border: none;
  background-color: #1167e5;
  color: white;
}

.render-cancel-btn {
  width: 173px;
  height: 48px;
  display: flex;
  padding: 0 28px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: none;
  background-color: rgb(68, 228, 59);
  color: white;
}

.render-cancel-btn:hover {
  background-color: rgb(59, 199, 16);
}

.render-btn:hover {
  background-color: #1167e5;
}

.render-btn-text {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.right-btn {
  display: flex;
  flex-direction: column;
  gap: 21px;
}

.control-buttons-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

.video-preview {
  width: auto;
  height: auto;
  border-radius: 5px;
}

.video {
  width: 865px;
  height: 461px;
  border-radius: 5px;
}

.seek-play-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 10px;
  background-color: #1167e5;
  padding: 8px;
  width: 32px;
  height: 32px;
}

.full-length {
  background-color: #d9d9d9;
  border-radius: 5px;
  height: 8px;
  width: 689px;
  margin: 0;
}

.played-length {
  background-color: var(--primary-01);
  border-radius: 5px;
  height: 8px;
  width: 263px;
}

.video-duration {
  color: #212121;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.video-seek-controls {
  display: flex;
  align-items: center;
}

.video-note {
  width: 865px;
  height: 214px;
  border-radius: 5px;
  background: #f4f4f4;
  /* padding: 1px 14px; */
}

.video-note-text {
  margin: 0;
}

textarea {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  resize: none;
}

.note-textarea {
  background-color: #f4f4f4;
  padding: 10px;
  color: var(--dark-01, #212121);
}

.note-text {
  color: var(--dark-01, #212121);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.arrow-icon {
  display: flex;
  align-items: center;
  gap: 10px;
}

.right-panel-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.note-arrow-container {
  width: 865px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -5px;
}

.current-count {
  color: #1167e5;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.total-count {
  color: #7a7a7a;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.slide-counter-container {
  display: flex;
  margin-right: 10px;
}

.speaker-select-button {
  display: flex;
  padding: 13px 28px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: var(--primary-01, #1167e5);
  border: none;
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.user-comment {
  margin-bottom: 20px;
  background-color: #f4f4f4;
  border-radius: 5px;
  padding: 5px;
  padding-top: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.comment-total {
  display: flex;
  gap: 10px;
}

.add-comment {
  display: flex;
  align-items: center;
  gap: 10px;
}

.add-comment-btn {
  width: auto;
  height: 40px;
  display: flex;
  padding: 0 28px;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: none;
  background-color: #1167e5;
  color: white;
  justify-content: center;
}

.comment-section-header {
  margin-bottom: 10px;
}

.comment-textarea {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.comment-textarea:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.comment-options {
  color: black;
}

.comment-options:hover {
  color: black;
}

.audio-options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.audio-buttons {
  margin-top: -20px;
  display: flex;
  gap: 10px;
}

.audio-btn {
  height: 47px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #fff;
  border: 1px solid #1167e5;
  border-radius: 5px;
  font-weight: 300;
}

.audio-btnselected {
  background-color: #1167e5;
  height: 47px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid #1167e5;
  color: white;
  border-radius: 5px;
  font-weight: 300;
}

.options-container {
  background-color: #f2f2f2;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
