:root {
  --textPrimaryBlack: #0A0A0A;
  --primaryBlue: #1167E5;
  --buttonBlue: #2962FF;
  --inputFields: #F4F4F4;
  --buttonColor: #FFFFFF;
  --navButton: #0045AA;
  --actionBlack: #212121;
  --dark-04: #A6A6A6;
  --dark-06: #E9E9E9;
  --primary-01: #1167E5;
  --white: #FFF;
  --dark-02: #4D4D4D;
  --dark-03: #7A7A7A;
  --dark-01: #212121;
  --dark-07: #F4F4F4;
}

/* global.css */
* {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

button:hover {
  background-color: var(--navButton);
  cursor: pointer;
  color: white;
}
input:focus {
  outline: none;
  border: none;
  border-bottom: 2px solid var(--primary-01);
}
input:active {
  outline: none;
  border: none;
}

