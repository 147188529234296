.loading-button-container {
    background-color: var(--buttonBlue);
    color: var(--buttonColor);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    border: none;
    border-radius: 5px;
    width: 100%;
    height: 100%;

}

.loadingdots:nth-child(2) {
    animation-delay: 0.2s;
}

.loadingdots:nth-child(3) {
    animation-delay: 0.4s;
}

.loadingdots{
    background-color: var(--buttonColor);
    font-size: 28px;
    display: inline-block;
    position: relative;
    animation: loadingAnimation 1s infinite;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin: 0px 3px;
}

@keyframes loadingAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}