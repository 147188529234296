.ppt-slides-sidebar-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    margin-left: 25px;
}

.ppt-slides-sidebar-body{
    overflow-y: scroll;
    height: 109vh;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.slide-preview-vid-container{
    width: 318px;
    height: 196px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.ppt-slides-sidebar-header{
    display: flex;
    align-items: center;
    gap: 10px;
}


.slide-preview-img-container{
    width: 318px;
    height: 196px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    gap: 10px;
}

.slide-preview-vid-container-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slide-preview-img-container .delete-slide-container{
    visibility: hidden;
}

.slide-preview-img-container:hover .delete-slide-container{
    visibility: visible;
}

.slide-preview-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}