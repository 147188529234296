.file-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }

  .add-slide-avatar-container{
    display: flex;
    width: 535px;
    justify-content: space-between;
  }
  
  .dropzone {
    border: 2px dashed #cccccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    height: 188px;
  }
  
  .file-preview {
    margin-top: 20px;
  }
  
  .preview-image {
    max-width: 100%;
    max-height: 300px;
  }
  
  .preview-video {
    max-width: 100%;
    max-height: 300px;
  }

  .success-message {
    color: #4caf50;
    font-weight: bold;
    margin-top: 10px;
  }

  .remove-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
    margin-top: 10px;
  }
  
  .remove-button:hover {
    background-color: #d32f2f;
  }