.assign-user-btn{
    display: flex;
    padding: 13px 28px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
    color: white;
    border-radius: 5px;
    background: var(--primary-01, #1167E5);
}

.card-icons-tray-container{
    display: flex;
    align-items: center;
    gap: 11px;
    margin-left: 10px;
}

html .disable-cursor{
    pointer-events: none;
    cursor: not-allowed !important;
}

/* Custom styles for the TabList */
.tabList {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
}

/* Custom styles for the Tab */
.tab {
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 8px 8px 0 0;
  background-color: #f0f0f0;
  transition: background-color 0.3s ease;
}

.tab:hover {
  background-color: #e0e0e0;
}

/* Custom styles for the active Tab */
.tab.active {
    background: var(--primary-01, #1167E5);
    border-bottom: 2px solid #007bff; /* Adjust color as needed */
}

/* Custom styles for the TabPanel */
.tabPanel {
  padding: 20px;
  border: 1px solid #ddd;
  border-top: none;
}

/* Custom styles for the Box container */
.boxContainer {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}
