.customFolder-modal {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    top: 0px;
    left: 0px;   
    background-color: rgba(217, 217, 217, 0.50);;
}
.customFolder-modal-content {
    left: 50%;
    top: 50%;
    width: 30%;
    background-color: white;
    /* height: 40%; */
    position: absolute;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
}
.customFolder-modal-content-top {
    display: flex;
    justify-content: space-between;
}
#customFolder-closeIcon:hover {
    cursor: pointer;
}
.customFolder-modal-heading{
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 150% */
}
.customFolder-modal-folderName {
    width: 100%;
    height: 60px;
    border: none;
    border-radius: 5px;
    background: var(--inputFields);
    padding: 0px 17px;
}
.customFolder-modal-buttonSection{
    display: flex;
    flex-direction: row;
    padding-top: 30px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.customfolder-loadingbutton-wrapper{
    width: 100%;
    height: 47px;

}
.customFolder-modal-buttons {
    display: flex;
    padding: 15px 32px;
    align-items: center;
    gap: 8px;
    background-color: var(--primaryBlue);
    border: none;
    border-radius: 5px;
    color: var(--buttonColor);
}
#customFolder-modal-buttons-cancel {
    background-color: var(--white);
    color: var(--primaryBlue);
    border: 1px solid var(--primaryBlue);
}
#customFolder-modal-buttons-cancel:hover {
    background-color: var(--dark-07);
}
.customFolder-modal-error{
    display: inline-block;
    min-height: 19px;
    font-size: 14px;
    padding-top: 2px;
}