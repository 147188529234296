.folder-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.folder-directory{
    align-items: center;
    display: flex;
    gap: 16px;
}
.folder-packet {
    display: inline-flex;
    /* padding: 12px 28px; */
    align-items: center;
    border-radius: 5px;
    background: var(--dark-07, #F4F4F4);
    flex-direction: column;
    height: 80px;
    min-width: 150px;
    justify-content: center;
}

.folder-main {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    gap: 4px;

}

.folder-eclipse {
    display: flex;
    gap: 2px;
    justify-content: flex-end;
    width: 100%;
    position: relative;
    padding: 4px;
    margin-right: -50px;
    margin-bottom: -10px;
}


.folder-eclipse:hover {
    cursor: pointer;
}

.eclipse-dot {
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: blue;
}

.folder-eclipse-options {
    background-color: var(--white);
    position: absolute;
    left: 120px;
    top: 20px;
    z-index: 20;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.06);
    display: none;
}

.folder-eclipse-action {
    display: inline-flex;
    gap: 14px;
    padding-right: 30px;
    padding-left: 10px;
    margin: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.folder-eclipse-action:hover {
    background-color: var(--dark-07);
    cursor: pointer;
}

.folder-name {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.folder-rename-options {
    width: 200px;
    position: absolute;
    top: 60px;
    left: 10px;
    display: none;
    z-index: 21;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 8px 50px 0px rgba(0, 0, 0, 0.06);
}

.rename-folder-options-menu {
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
}

.folder-rename-crossIcon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.folder-rename-header {
    display: flex;
    gap: 10px;
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    /* 150% */
}

.folder-rename-inputField {
    display: flex;
    padding: 5px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    background-color: var(--dark-07);
    border: none;
    outline: none;
}

.rename-options-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    padding: 8px 0px;
}

.rename-options-buttons {
    display: flex;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: var(--primary-01, #1167E5);
    border: 1px solid var(--primary-01, #1167E5);
}

.rename-options-done-button{
background: var(--primary-01, #1167E5);
color: white;
}

.rename-action-error-span{
    font-size: 12px;
    min-height: 16px;
}


.folder-delete-options {
    width: 230px;
    position: absolute;
    top: 60px;
    left: 10px;
    display: none;
    z-index: 21;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 8px 50px 0px rgba(0, 0, 0, 0.06);
}

.folderlist-right-button{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--primary-01, #1167E5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    cursor: pointer;
}