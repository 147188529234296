/* .react-player-container{
    display: flex;
    gap: 45px;
}

.react-player-right{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.react-player-header{
    margin-top: 30px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.react-player-body{
    display: flex;
} */

/* Add these styles for overlay */
.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 5px;
}

.overlay-content h1 {
  color: #333;
  font-size: 24px;
  margin-bottom: 10px; /* Add margin bottom for spacing */
}

.overlay-content p {
  color: #666;
  font-size: 16px;
}

/* Additional styles for the ReactPlayer container */
.react-player-container {
  display: flex;
  gap: 45px;
}

.react-player-right {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.react-player-header {
  margin-top: 30px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.react-player-body {
  display: flex;
  width: 80vw;
}

.question-button-container {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
  width: 80vw;
}

.skip-button,
.submit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #4CAF50;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.skip-button:hover,
.submit-button:hover {
  background-color: #45a049;
}

.dynamic-question{
  height: 100%;
}