.signin-main {
    color: var(--textPrimaryBlack);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.signin-left-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 100vh;
    background-color: var(--primaryBlue);
}

.signin-right-panel {
    width: 60%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
}

.signin-form {
    width: 80%;
    max-height: 50%;
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}


#signin-form-heading {
    font-size: 24px;
    font-weight: 700;
    font-family: "Lato", sans-serif;
    margin-bottom: 40px;
}

.signin-form-fields {
    min-width: 100%;
    max-width: 100%;
    height: 50px;
    padding: 16px 16px 16px 16px; 
    border: none;
    background-color: var(--inputFields);
    box-sizing: border-box;
    border-radius: 5px;
}

.signin-form-fields:focus {
    outline: none;
    border-bottom: 2px solid var(--primaryBlue);
}


.signin-form-input-error {
    font-size: 14px;
    color: red;
    font-weight: 100;
    margin-bottom: 16px;
    letter-spacing: 0.8px;
    display: inline-block;
    min-height: 19px;
    
}

#signin-form-submit {
    background-color: var(--buttonBlue);
    color: var(--buttonColor);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-top: 16px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}
#signin-form-submit:hover {
    cursor: pointer;
    background-color: var(--navButton);
}
.signin-form-arrow-right{
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.loadingdots{
    background-color: var(--buttonColor);
    font-size: 28px;
    display: inline-block;
    position: relative;
    animation: loadingAnimation 1s infinite;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin: 0px 3px;
    
}
#signin-form-loading {
    margin-top: 55px;
    background-color: var(--buttonBlue);
    color: var(--buttonColor);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-top: 16px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes loadingAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}

.signin-form-fields {
    position: relative;
}

.loadingdots:nth-child(2) {
    animation-delay: 0.2s;
}

.loadingdots:nth-child(3) {
    animation-delay: 0.4s;
}

.avetiBranding{
    background-color: transparent;
    position: absolute;
    top: 5%;
    left: 5%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.avetiproductname {
    color: var(--buttonColor);
    font-weight: 700;
    font-family: "Lato", sans-serif;
    font-size: 40px;
}
