.home-left-panel {
    width: 15%;
    background-color: var(--buttonColor);
    box-shadow: 0px 8px 50px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}

.home-avetiBranding {
    background-color: transparent;
    padding-top: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.home-avetiproductname {
    color: var(--textPrimaryBlack);
    font-weight: 700;
    font-family: "Lato", sans-serif;
    font-size: 20px;
    margin-top: 40px;
}

.home-aveti-nav {
    width: 80%;
}

.home-aveti-left-nav-ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    border-radius: 5px;
}

/* .home-aveti-left-nav-ul-li {
    list-style: none;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    gap: 20px;
    padding-left: 10px;
    flex-direction: row;
    border-radius: 5px;
}

.home-aveti-left-nav-ul-li.selected {
    color: var(--buttonColor);
    font-family: "Lato", sans-serif;

    background-color: var(--navButton);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
} */

.custom-list{
    background-color: bisque !important;
    list-style: none;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    gap: 20px;
    padding-left: 10px;
    flex-direction: row;
    border-radius: 5px;
}

.custom-list.selected{
    color: var(--buttonColor);
    font-family: "Lato", sans-serif;

    background-color: var(--navButton);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}