.preview-audio-option-container{
    display: flex;
    gap: 10px;
}

.audio-option-btn{
    height: 47px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: #fff;
    border: 1px solid #1167E5;
    border-radius: 5px;
    font-weight: 300;
}