.home-main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

.home-left-panel {
    width: 15%;
    min-height: 100%;
    background-color: var(--buttonColor);
    box-shadow: 0px 8px 50px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.home-right-panel {
    width: 85%;
    height: 100%;

}

.home-avetiproductname {
    color: var(--textPrimaryBlack);
    font-weight: 700;
    font-family: "Lato", sans-serif;
    font-size: 20px;
    margin-top: 40px;
}


.home-avetiBranding {
    background-color: transparent;
    padding-top: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.home-aveti-nav {
    width: 80%;
}

.home-aveti-left-nav-ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    border-radius: 5px;
    gap: 10px;
}

.home-aveti-left-nav-ul-li {
    list-style: none;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    gap: 20px;
    padding-left: 10px;
    flex-direction: row;
    border-radius: 5px;
}

.home-aveti-left-nav-ul-li:nth-child(1) {
    color: var(--buttonColor);
    font-family: "Lato", sans-serif;

    background-color: var(--navButton);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}


.home-right-panel-header {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 50px;
    padding-right: 20%;
}

.home-right-panel-header-myppt {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    gap: 10px;
}
.home-left-panel-logout:hover{
    background-color: var(--dark-07);
}

.home-right-panel-header-search{
    display: flex;
    align-items: center;
    padding: 10px 10px;
    background-color: var(--dark-07);
    gap: 10px;

}
.home-right-panel-header-search-field {
    padding: 2px 10px;
    background-color: transparent;
    border: none;
    border-right: 1px solid black;
    width: 400px;
}

.home-right-panel-header-search-field:focus {
    border: none;
    border-right: 1px solid black;
    outline: none;
}
.home-right-panel-folder-create {
    height: 15%;
    width: 100%;
    padding-left: 40px;
    gap: 10px;
}

.home-right-panel-upload-form {
    height: auto;
    width: 100%;
    padding-top: 20px;
}

.home-right-panel-folder-create {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.home-right-panel-folder-create-new {
    width: 40px;
    height: 80%;
    background-color: var(--inputFields);
    display: flex;
    width: 300px;
    align-items: center;
    justify-content: space-between;
    padding: 8px 14px;
    border-radius: 5px;
}

.home-right-panel-folder-create-new-title {
    color: var(--actionBlack);
    font-family: "Lato", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.home-right-panel-folder-action {
    display: flex;
    padding: 2px 18px;
    align-items: center;
    gap: 8px;
    background-color: var(--primaryBlue);
    border: none;
    border-radius: 5px;
    color: var(--buttonColor);
}

#home-right-create-folder {
    border: 1px dashed var(--dark-04);
    background-color: var(--buttonColor);
}

.home-right-panel-upload-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.home-right-form-data {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 20px 0px;
    gap: 5px;
}

.home-right-panel-form-data-labels {
    color: #000;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    width: 100%;
}

.home-right-panel-form-data-input {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 5px;
    background: var(--dark-06);
    padding: 0px 17px;
}

.home-right-panel-form-data-input-container {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 5px;
    background: var(--dark-06);
    padding: 0px 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home-right-panel-form-data-input-field {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
}

.home-right-panel-form-data-input-field:focus {
    outline: none;
    border: none;
}


.home-right-panel-form-data-input-menu {
    position: absolute;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 8px 50px 0px rgba(0, 0, 0, 0.06);
    width: 150px;

}

.home-right-panel-form-data-input-menu-options {
    background-color: white;
    padding: 10px 10px;
}


.home-right-panel-form-data-input-menu-options:hover {
    background-color: var(--inputFields);
    width: 100%;
    cursor: pointer;
}

#home-right-panel-form-data-input-menu-Lang {
    position: relative;
    top: -120px;
}


.wrapper {
    position: absolute;
    height: 0px;
}

.home-right-form-data-button {
    height: 40px;
    border-radius: 5px;
    border: none;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background: var(--dark-04, #A6A6A6);
    color: var(--white);
}

#dragNdrop-ppt-conversion {
    font-size: 12px;
    color: var(--dark-03);
    font-weight: 700;
    line-height: 17px;
}

#dragNdrop-max-file-size {
    font-size: 7px;
    color: var(--dark-03);
    font-weight: 500;
    line-height: normal;
}

#dragNdrop-drag-drop-ppt {
    color: var(--dark-02);
    text-align: center;
    font-size: 13.586px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

#dragNdrop-drag-drop-or {
    color: var(--dark-03);
    text-align: center;
    font-size: 11.645px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

#dragNdrop-button {
    border: none;
    border-radius: 2.426px;
    background: var(--primary-01, #1167E5);
    color: var(--white, #FFF);
    font-family: 'Open Sans', sans-serif;
    padding: 5px 25px;
    margin-bottom: 5px;
}

#dragNdrop-button:hover {
    cursor: pointer;
    background-color: var(--navButton);
}

#home-right-dragNdrop-main-input {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
    opacity: 0;
}
#home-right-dragNdrop-main-input:focus {
border: none;
}
#dragNdrop-form-loadingButton {
    width: 50%;
    height: 40px;
    margin: 0px;
}
#home-right-dragNdrop-form-error {
    display: block;
    font-size: 14px;
    color: red;
    font-weight: 100;
    letter-spacing: 0.8px;
    min-height: 19px;
}
.upload-file-cancel-icon {
    position: relative;
    z-index: 20;
}
.upload-file-cancel-icon:hover {
    cursor: pointer;
}
.uploaded-file-name {
    font-size: 14px;
    font-weight: 500;
    color: var(--dark-01);
    margin-right: 15px;
}
.uploaded-file-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.home-folder-list-wrapper {
    padding-left: 40px;
    padding-top: 30px;
}

.home-right-panel-list{
    padding: 35px;
}