.course-accordian-header .edit-course-button-container{
    visibility: hidden;
}

.course-accordian-header:hover .edit-course-button-container{
    visibility: visible;
}

.add-chapter-fab-container{
    margin: 20px 20px 20px 8px;
}