.user-page{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding: 0px;
    margin-top: 10px;
    box-sizing: border-box;
    gap: 60px;
}

.page-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.user-right-panel{
    width: 95%;
    padding-top: 10px;
    padding-right: 10px;
}

.user-right-panel-header{
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-user{
    display: flex;
    padding: 12px 32px;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    background: var(--primary-01, #1167E5);
    border: none;
    width: 143px;
    height: 48px;
}

.add-user-text{
    color: var(--White, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.user-card-header{
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
}

.user-table{
    margin-top: 10px;
}

.category{
    border: 2px solid #1167E5;
    border-radius: 5px;
}