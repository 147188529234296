.slider-container{
    --thumb-width: 20px;
    --thumb-height: 20px;
}

.range::-webkit-slider-thumb{
    -webkit-appearance: none;
    appearance: default;
    width: var(--thumb-width);
    height: var(--thumb-height);

    background-color: #ee06bc;
    border-radius: 50%;
    cursor: pointer;
}

.thumb{
    width: var(--thumb-width);
    height: var(--thumb-height);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.753);
    z-index: 3;
    background: rgb(255, 255, 255);
    position: absolute;
    border-radius: 50%;
    top: 50%;
    transform: translate(0%, -50%);
    pointer-events: none;
    user-select: none;
}
