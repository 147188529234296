.myvideos-main-container {
    width: 100%;
    height: 100px;
    overflow-y: scroll;
}

.myvideos-main-container::-webkit-scrollbar {
    display: none;
    width: 8px;
    background-color: var(--dark-07);
}

.myvideos-main-container::-webkit-scrollbar-thumb {
    background-color: var(--primaryBlue);
    border-radius: 5px;
}

.myvideos-header {
    color: var(--dark-01, #212121);
    font-size: 24px;
    font-weight: 600;
    padding: 10px;
}

.myvideos-video {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 15px;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 8px 50px 0px rgba(0, 0, 0, 0.08);
}

.myVideo-video-name {
    color: var(--dark-01);
    font-weight: 600;
}

.myVideo-video-duration {
    color: var(--dark-03);
}

.myVideo-video-date {
    color: var(--dark-03);
}

.myVideo-video-Actions {
    display: flex;
    align-items: center;
    gap: 30px;
}

.myVideo-video-Actions>img {
    cursor: pointer;
}

.myvideos-action-modal {
    position: absolute;
    top: 0px;
    background: rgba(217, 217, 217, 0.50);
    z-index: 12;
    width: 100%;
    height: 100%;
}

.myvideos-action-modal-content {
    width: 30%;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    color: var(--dark-01, #212121);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10px 15px;

}
.myvideos-action-modal-content-close {
    float: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    margin: 0px;
}

.myvideos-modal-action-gist {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#myvideos-modal-delete-danger{
    border-radius: 5px;
    background: rgba(176, 0, 32, 0.20);
    width: 30px;
    height: 30px;    
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}
.myvideos-modal-action-buttons{
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}
.myvideos-modal-button {
    margin-top: 50px;
    display: flex;
    padding: 10px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--primaryBlue);
    border: 1px solid var(--primaryBlue);
    border-radius: 5px;
    background-color: white;
}

.myvideos-positive-button {
    background-color: var(--primaryBlue);
    color: white;
    border: none;
}
.myvideos-tts-input{
 background-color: transparent;
 border: none;
 outline: none;
}
.myvideos-tts-input:focus {
    outline: none;
    border: none;
}
.myvideos-translate-tts{
    height: 40px;
    padding: 0px 10px;
    background-color: var(--dark-07);
    border: none;
    outline: none;
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;
}
.myvideos-translate-options{
    position: absolute;
    background-color: white;
    top: 50%;
    width: calc(100% - 30px);
}
.myvideos-translate-choice {
    font-weight: 500;
    cursor: pointer;
    padding: 0px 10px;
}