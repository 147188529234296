.slider-container{
    --progress-bar-height: 8px;
}

.slider-container{
    position: relative;
    width: 100%;
}

.range{
    -webkit-appearance: none;
    appearance: default;
    background-color: rgba(8, 80, 214, 0.644);
    height: 10px;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    margin: 0 auto;
}

.slider-container::before {
    content: "";
    background-color: #D9D9D9;
    width: 99%;
    height: var(--progress-bar-height);
    display: block;
    position: absolute;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    opacity: 1;
}

.progress-bar-cover {
    background-color: var(--primary-01, #1167E5);
    width: 100%;
    height: var(--progress-bar-height);
    display: block;
    position: absolute;
    border-radius: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    user-select: none;
    pointer-events: none;
}
