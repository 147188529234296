table{
    width: 100%;
}
tr {
    width: 100%;
}
td {
    width: 25%;
    text-align: left;
    min-height: 120px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
}