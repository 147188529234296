.frame {
    align-items: center;
    display: flex;
    gap: 11px;
    justify-content: center;
    position: relative;
    width: 865px;
    margin-top: 10px;
  }
  
  .frame .div {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 11px;
    position: relative;
  }
  
  .frame .div-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
  }
  
  .frame .img {
    flex: 0 0 auto;
    position: relative;
  }
  
  .frame .group {
    background-color: #d9d9d9;
    border-radius: 5px;
    height: 8px;
    position: relative;
    width: 689px;
  }
  
  .frame .rectangle {
    background-color: var(--primary-01);
    border-radius: 5px;
    height: 8px;
    width: 263px;
  }
  
  .frame .text-wrapper {
    color: var(--dark-01);
    font-family: "Lato", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .frame .div-3 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 11px;
    position: relative;
  }
  
  .frame .img-2 {
    height: 24px;
    position: relative;
    width: 24px;
  }
  
.play-btn-play{
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 10px;
  background: var(--primary-01, #1167E5);
  border: none;
}