.textarea-context-menu{
    position: absolute;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1);
    background-color: rgb(235, 235, 235);
    border-radius: 10px;
    width: 200px;
    height: 335px;
    overflow-y: scroll;
}

.context-menu-ul {
  list-style-type: none;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
}

.context-menu-li {
  padding: 18px 12px;
  border-radius: 4px;
  font-size: 20px;
  font-weight: bold;
}

.context-menu-li:hover {
  cursor: pointer;
  background-color: #5775f9;
}