
.home-right-dragNdrop-main-container {
    height: 68vh;
    overflow-y: auto;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-right-dragNdrop-main-container > div {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

.home-right-form-data {
    margin-top: 20px;
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
}

.home-right-panel-form-data-input-container {
    position: relative;
    margin-top: 5px;
}

.home-right-panel-form-data-input-field {
    width: calc(100% - 40px);
    padding: 10px;
    border-radius: 5px;
}

.home-right-panel-form-data-input-menu-TTS {
    position: absolute;
    width: calc(50% - 2px);
    max-height: 150px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 5px 5px;
    z-index: 1;
    top: calc(100% + 2px); /* Position dropdown below the input field */
    right: 1px;
}

.home-right-panel-form-data-input-menu-Lang{
    position: absolute;
    width: calc(100% + 20px);
    max-height: 150px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 5px 5px;
    z-index: 1;
     /* Position dropdown below the input field */
    left: -85px;
}

#home-right-panel-form-data-input-menu-Lang{
    position: relative;
    top: 8px;
}

.home-right-panel-form-data-input-menu-Speaker{
    position: absolute;
    width: calc(50% + 50px);
    max-height: 150px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 5px 5px;
    z-index: 1;
    top: calc(100% + 100px); /* Position dropdown below the input field */
    right: 0px;
}

.home-right-panel-form-data-input-menu-options {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.home-right-panel-form-data-input-menu-options:hover {
    background-color: #f5f5f5;
}

.home-right-form-data-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.home-right-form-data-button:hover {
    background-color: #0056b3;
}

#dragNdrop-max-file-size {
    font-size: x-small;
}

body.disable-cursor {
    pointer-events: none;
    cursor: not-allowed !important;
}
