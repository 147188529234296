.course-main-container{
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    height: 100%;
}


.course-right-panel-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.course-right-header-title{
    display: flex;
    align-items: center;
    gap: 5px;
}

.course-right-panel{
    width: 100%;
    margin-top: 20px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.add-category-button {
    /* background-color: #1167E5; */
    color: black;
    padding: 10px;
    border: none;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
    margin-left: 10px;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.add-category-button:hover {
    background-color: #0e5fca;
}

.course-catalogue-container{
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
}

.course-catalogue-options{
    display: flex;
    gap: 15px;
}