.slide-note-text-editor-container{
    margin-top: 25px;
}

.ppt-slide-update-options-container{
    margin-top: 10px;
}

.ppt-slide-update-options-button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 56vw;
}

.note-update-btn-slide-update{
    width: 200px;
    max-height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: none;
    background: #0045AA;
}

.note-btn-text-slide-update{
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 540;
    line-height: normal;
}

.play-btn-slide-update{
    width: 200px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: #fff;
    border: 1px solid #1167E5;
    border-radius: 5px;
}

.play-btn-slide-update:hover{
    background-color: #fff;
}

.play-btn-text-slide-update{
    color: #1167E5;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
