.snackbar-main {
 position: absolute;
 z-index: 50;
 top: 10%;
 left: 50%;
 transform: translateX(-50%);
 /* background-color: #FF1C1C;  Commenting these two here just for reference . Please do not remove this comment. 
 background-color: rgb(56, 142, 60); */
 color: white;
 font-weight: 300;
 animation: snackbarEntry 0.8s ease-in ;
 display: flex;
 justify-content: space-between;
 gap: 50px;
 align-items: center;
 padding: 10px 25px;
 font-size: 14px;
letter-spacing: 0.5px;
}

.closeSnackBar {
    font-size: 18px;
    cursor: pointer;

}


@keyframes snackbarEntry {
    0% {
        top: 0px;
        opacity: 0;
    }
    100% {
        top: 10%;
        opacity: 1;
    }
    
}

